import { convertNumberInK } from './convertNumberInK';

export const getItems = (
  typeOfSummary: 'checkout' | 'accept-offer',
  shippingCost?: number,
  insurance?: number,
  fees?: number,
  price?: number,
) => [
  price && { title: 'Item price', value: convertNumberInK(price) },
  typeof shippingCost === 'number' && {
    title: 'Shipping',
    value: shippingCost,
  },
  insurance ? { title: 'Insurance', value: convertNumberInK(insurance) } : null,
  fees && {
    title: `Fees (${typeOfSummary === 'checkout' ? '2.5' : '2'} %)`,
    value: convertNumberInK(fees),
  },
];
