import { MouseEvent, FC, memo } from 'react';
import './smallCardInfo.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ISmallCardInfoProps } from './types';
import { getRouteNFTCard } from '@/shared/const/router';
import { limitWordCharacters } from '@/shared/lib/limitWordCharacters/limitWordCharacters';

export const SmallCardInfo: FC<ISmallCardInfoProps> = memo(
  ({
    name,
    tokenId,
    image,
    isTitleShort,
    handleModalClose,
    lettersLimit,
    isError,
  }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const currentNetwork = environment.NETWORK;

    const maxTitleCharLenght = isTitleShort ? 47 : 85;

    const redirectToNFT = () => {
      handleModalClose && handleModalClose();
    };

    const openSolscan = (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      window.open(
        `https://solscan.io/address/${tokenId}?cluster=${currentNetwork === 'devnet' ? 'devnet' : 'mainnet'
        }`,
        '_blank',
      );
    };

    const mainView = () => (
      <>
        <div className={`small-card-info__image ${isError && 'red-border'}`}>
          <img src={image} alt='card example' loading='lazy' />
        </div>
        <div className='small-card-info__text-block'>
          <div className='small-card-info__text-block__name'>
            {limitWordCharacters(
              name,
              lettersLimit || maxTitleCharLenght,
              'endDots',
            )}
          </div>
          <div className='small-card-info__text-block__token'>
            <div className='small-card-info__text-block__token__id'>
              Token ID
            </div>
            <div
              className='small-card-info__text-block__token__value'
              onClick={openSolscan}
            >
              {limitWordCharacters(tokenId, 5, 'centerDots')}
            </div>
          </div>
        </div>
      </>
    );

    return pathname.includes('/assets/solana/') ? (
      <div className='small-card-info' onClick={redirectToNFT}>
        {mainView()}
      </div>
    ) : (
      <Link to={getRouteNFTCard(tokenId)}>
        <div className='small-card-info'>{mainView()}</div>
      </Link>
    );
  },
);
