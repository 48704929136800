import './app/styles/index.scss';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './app/App';
import AuthProvider from './app/providers/AuthProvider/AuthProvider';
import { CheckWalletProvider } from './app/providers/CheckWalletProvider';
import { ErrorBoundary } from './app/providers/ErrorBoundary/ErrorBoundary';
import { MagicLinkProvider } from './app/providers/MagicLinkProvider/MagicLinkProvider';
import { StoreProvider } from './app/providers/StoreProvider/ui/StoreProvider';
import { ThemeProvider } from './app/providers/ThemeProvider';
import { WalletContextProvider } from './app/providers/WalletContextProvider';
import { NotificationStream } from './entities/Notifications';
import reportWebVitals from './reportWebVitals';


environment.SIGNIN_GOOGLE_ID =
  process.env.SIGNIN_GOOGLE_ID || environment.SIGNIN_GOOGLE_ID || '';
environment.API_URL =
  process.env.API_URL ||
  environment.API_URL ||
  'https://dev-api.collectorcrypt.com/';
environment.GOOGLE_ANALYTICS =
  process.env.GOOGLE_ANALYTICS ||
  environment.GOOGLE_ANALYTICS ||
  'G-N6KT72LESQ';
environment.SENTRY_AUTH_TOKEN =
  process.env.SENTRY_AUTH_TOKEN || environment.SENTRY_AUTH_TOKEN || '';
environment.NETWORK = process.env.NETWORK || environment.NETWORK || 'devnet';
environment.APP_ENV = process.env.APP_ENV || environment.APP_ENV || 'local';
environment.SENTRY_DSN =
  process.env.SENTRY_DSN ||
  environment.SENTRY_DSN ||
  'https://5f40c59d4cef4a6283ef534ef12cadcf@o1363123.ingest.sentry.io/6680400';
environment.RPC_URL = process.env.RPC_URL || environment.RPC_URL || 'https://api.devnet.solana.com';

environment.APP_ENV !== 'local' &&
  ReactGA.initialize(environment.GOOGLE_ANALYTICS);

const container = document.getElementById('root');

if (!container) throw new Error('Error load app');

const root = createRoot(container);

root.render(
  <Router>
    <StoreProvider>
      <ErrorBoundary>
        <ThemeProvider>
          <WalletContextProvider>
            <MagicLinkProvider>
              <CheckWalletProvider>
                <AuthProvider>
                  <NotificationStream>
                    <App />
                  </NotificationStream>
                </AuthProvider>
              </CheckWalletProvider>
            </MagicLinkProvider>
          </WalletContextProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </StoreProvider>
  </Router>,
);

reportWebVitals();
