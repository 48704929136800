import { CSSProperties, FC, memo, useMemo } from 'react';
import cls from './summary.module.scss';
import { CurrencyDropdown } from '@/features/CurrencyDropdown';
import { WalletSVG } from '@/shared/assets/svg';
import { classNames } from '@/shared/lib/classNames/classNames';
import { convertNumberInK } from '@/shared/lib/helpers/convertNumberInK';
import { getItems } from '@/shared/lib/helpers/currencyDropdown';
import { HStack, VStack } from '@/shared/ui/Stack';

interface ISummaryProps {
  shippingCost?: number;
  insurance?: number;
  fees: number;
  currency: ICurrency;
  theme?: 'dark' | 'light';
  price?: number;
  typeOfSummary: 'checkout' | 'accept-offer';
  isPDF?: boolean;
}

export const Summary: FC<ISummaryProps> = memo(props => {
  const {
    shippingCost,
    insurance = 0,
    fees,
    currency,
    theme = 'dark',
    price,
    typeOfSummary,
    isPDF,
  } = props;

  const items = getItems('accept-offer', shippingCost, insurance, fees, price);
  const lightTheme = theme === 'light';

  const totalName = useMemo(
    () => (typeOfSummary === 'checkout' ? 'total' : 'Total earnings'),
    [typeOfSummary],
  );

  const resultTotal = useMemo(
    () =>
      typeOfSummary === 'checkout'
        ? convertNumberInK((shippingCost || 0) + fees + insurance)
        : price && convertNumberInK(price - fees),
    [fees, insurance, price, shippingCost, typeOfSummary],
  );

  const titleClass = useMemo(
    () =>
      classNames('', {
        [cls['data-title']]: lightTheme,
        [cls['data-type']]: isPDF,
      }),
    [isPDF, lightTheme],
  );

  const valueClass = useMemo(
    () =>
      classNames('', {
        [cls['data-value']]: lightTheme,
        [cls['data-type']]: isPDF,
      }),
    [isPDF, lightTheme],
  );

  const listCardInputStyles: CSSProperties = {
    alignItems: 'center',
    background: '#121212',
    borderRadius: '0.5rem',
    color: '#E8EAED',
    display: 'flex',
    gap: '0.25rem',
    padding: '0.375rem 0.5rem 0.375rem 0.5rem',
    right: ' 1rem',
    top: '0.7rem',
  };

  return (
    <div
      className={classNames(cls['summary-black'], {
        [cls['summary-light']]: lightTheme,
      })}
    >
      <div>
        <HStack
          align='center'
          justify='between'
          gap={0.5}
          className={classNames(cls.title, {
            [cls['data-summary-title']]: lightTheme,
          })}
        >
          <HStack max justify='between'>
            <HStack gap={0.5} align='center'>
              <WalletSVG />
              <p>Summary</p>
            </HStack>
            <CurrencyDropdown
              currency={currency}
              styles={listCardInputStyles}
            />
          </HStack>
        </HStack>
        <VStack className={cls.details}>
          {items.map(
            item =>
              item && (
                <HStack key={item.title} max justify='between'>
                  <div className={titleClass}>{item.title}</div>
                  <div className={valueClass}>{item.value}</div>
                </HStack>
              ),
          )}
        </VStack>
        <HStack
          justify='between'
          className={classNames(cls.total, { [cls['data-type-total']]: isPDF })}
        >
          <div>{totalName}</div>
          <div>{resultTotal}</div>
        </HStack>
      </div>
    </div>
  );
});
