import { FC, MouseEvent, memo, useMemo } from 'react';
import cls from './cardRow.module.scss';
import { ICartCardProps } from '../../types';
import { changeUserCart } from '@/entities/Cart';
import { RedWarningCircleSVG } from '@/shared/assets/svg/AlertsIcons';
import { CloseWhiteSVG } from '@/shared/assets/svg/buttons';
import { getRouteNFTCard } from '@/shared/const/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { getCurrentSizeImageInTable } from '@/shared/lib/helpers/getCurrentSizeImageInTable/getCurrentSizeImageInTable';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { limitWordCharacters } from '@/shared/lib/limitWordCharacters/limitWordCharacters';
import { IconButton } from '@/shared/ui/Buttons';
import { Tooltip } from '@/shared/ui/Popovers';
import { HStack, VStack } from '@/shared/ui/Stack';
import { ListingStatus } from '@/shared/ui/Statuses';
import { Link } from 'react-router-dom';

export const CardRow: FC<ICartCardProps> = memo(props => {
  const {
    id,
    itemName,
    insuredValue,
    tokenId,
    frontImage,
    currentOwnerWallet,
    owner,
    theme = 'dark',
    orientation,
    listing,
    status,
    tokenIdClass,
    insuredClass,
    isPDF,
  } = props;
  const currentNetwork = environment.NETWORK;

  const dispatch = useAppDispatch();
  const lightTheme = theme === 'light';
  const { height, width } = getCurrentSizeImageInTable(orientation, 'm');

  const removeFromBurn = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(changeUserCart({ cardsId: [id], cartId: null }));
  };

  const contentName = useMemo(
    () => (
      <div
        className={classNames(cls.name, { [cls['data-name']]: lightTheme }, [])}
      >
        {itemName}
      </div>
    ),
    [itemName, lightTheme],
  );

  const leftImageClasses = useMemo(
    () =>
      classNames(
        cls.image,
        {
          [cls['data-border']]: lightTheme,
          [cls['red-border']]: currentOwnerWallet !== owner && !isPDF,
        },
        [],
      ),
    [currentOwnerWallet, isPDF, lightTheme, owner],
  );

  return (
    <div
      className={classNames(
        cls['card-row'],
        { [cls['data-border-card']]: lightTheme },
        [],
      )}
    >
      <div className={cls['left-block-wrapper']}>
        <HStack gap={1}>
          <HStack align='center' justify='center' className={leftImageClasses}>
            <ListingStatus
              listing={listing}
              status={status}
              withText={false}
              className={cls['listing-status']}
            />
            <img
              src={frontImage}
              width={width}
              height={height}
              loading='lazy'
              alt=''
            />
          </HStack>
          <VStack gap={0.325} className={cls['info-text']}>
            {!lightTheme ? (
              <Link to={getRouteNFTCard(tokenId)}>{contentName}</Link>
            ) : (
              contentName
            )}
            <HStack
              gap={0.375}
              className={classNames(
                cls.token,
                { [cls['data-price']]: lightTheme },
                [],
              )}
            >
              <HStack
                gap={0.375}
                className={classNames('', {}, [insuredClass])}
              >
                Token ID
              </HStack>
              <HStack
                gap={0.375}
                className={classNames('', {}, [
                  tokenIdClass,
                  cls['blue-token-text'],
                ])}
                onClick={() =>
                  window.open(
                    `https://solscan.io/address/${tokenId}?cluster=${currentNetwork === 'devnet' ? 'devnet' : 'mainnet'
                    }`,
                    '_blank',
                  )
                }
              >
                {currentOwnerWallet !== owner
                  ? `${tokenId?.slice(0, 14)}...`
                  : !!tokenId && limitWordCharacters(tokenId, 6, 'centerDots')}
              </HStack>
            </HStack>
          </VStack>
        </HStack>
        {currentOwnerWallet !== owner && !isPDF && (
          <HStack
            justify='between'
            align='end'
            className={classNames(cls.price, {}, [insuredClass])}
          >
            $ {insuredValue}
          </HStack>
        )}
      </div>
      {currentOwnerWallet !== owner && !isPDF ? (
        <HStack gap={1.125} align='center'>
          <div className={cls.button} onClick={removeFromBurn}>
            remove
          </div>
          <Tooltip
            placement='bottom'
            textWidth={'10.5rem'}
            titleText='You no longer own this pNFT. You can’t burn this  item! Please remove it to proceed'
          >
            <RedWarningCircleSVG />
          </Tooltip>
        </HStack>
      ) : (
        <VStack
          align='end'
          className={cls.right}
          gap={1.375}
          justify={lightTheme ? 'end' : 'start'}
        >
          {!lightTheme && (
            <IconButton onClick={removeFromBurn}>
              <CloseWhiteSVG />
            </IconButton>
          )}
          <div
            className={classNames(
              cls.price,
              {
                [cls['data-price']]: lightTheme,
              },
              [insuredClass],
            )}
          >
            ${insuredValue}
          </div>
        </VStack>
      )}
    </div>
  );
});
